import _ from 'lodash';
import { paginateArray } from "@utils";
import moment from 'moment';

export const filterHistory = ({ allData, params }) => {
  try {
    // console.log('filter, ', allData);
    // eslint-disable-next-line object-curly-newline
  //  console.log('allData', allData);
    const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = params;
    // console.log('sortColumn = ', sortColumn);
    let dataToSort = _.cloneDeep(allData);
    /* eslint-enable */

    const dataAsc = dataToSort.history.sort((a, b) => {
      if (sortColumn === 'total') {
        b = { ...b, total: Number(b.total) };
        a = { ...a, total: Number(a.total) };
      }
      // console.log('\n\nsort, ', sort, sortColumn);
    //  console.log('sort2, ', a, b);
      if (a[sortColumn]) {
        // console.log('a', a[sortColumn], b[sortColumn]);
        return a[sortColumn] < b[sortColumn] ? -1 : 1;
      } else {
        // console.log('ca else');
        const splitColumn = sortColumn.split('.');
        const columnA = a[splitColumn[0]][splitColumn[1]];
        const columnB = b[splitColumn[0]][splitColumn[1]];
        return columnA < columnB ? -1 : 1;
      }
    });
    // console.log('dataAsc', dataAsc);

    const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse();
  //  console.log('filter3');

    const queryLowered = q.toLowerCase();
  //  console.log('filter4');
    const filteredData = dataToFilter.filter(history => {
      // console.log('history = ', history);
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    //  console.log('history', history, queryLowered, String(history.id), String(history.id).includes(queryLowered));
      return (
        (String(history.id).includes(queryLowered) ||
          history.status.toLowerCase().includes(queryLowered) ||
          String(history.total).toLowerCase().includes(queryLowered) ||
          String(moment(history.date).format('DD/MM/YYYY')).toLowerCase().includes(queryLowered))
        && (!status || history.status.toLowerCase() === status.toLowerCase())
      );
    });
  //  console.log('filter5');
    /* eslint-enable  */

  //  console.log('filteredData', {
    //   allData: dataToSort.history,
    //   total: filteredData.length,
    //   history: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    // });
    return {
      allData: dataToSort.history,
      total: filteredData.length,
      history: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    };
  } catch (error) {
    // console.log(error);
  }
};