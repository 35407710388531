// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { filterHistory } from '../filters/filterHistory';

export const getData = createAsyncThunk('appHistoryList/getData', async (allData, params) => {
  // console.log('allData', allData)
  const response = filterHistory(allData, params);
//  console.log('la response = ', response)
  return {
    params,
    data: response.history,
    allData: response.allData,
    totalPages: response.total
  };
});

export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/invoice/delete', { id });
  await dispatch(getData(getState().invoice.params));
  return id;
});

export const appInvoiceSlice = createSlice({
  name: 'appHistoryList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
    });
  }
});

export default appInvoiceSlice.reducer;
