// ** Reducers Imports
import layout from './layout';
import navbar from './navbar';
// import invoice from '@src/containers/invoices-test/store/index.js'
import distributors from '@src/containers/Admin/AllDistributorsList/store/index.js';
import allCommercialsList from '@src/containers/Admin/AllCommercialsList/store/index.js';
import commercialsList from '@src/containers/Distributor/CommercialsList/store/index.js';
import billingClients from '@src/containers/Admin/BillingRequestList/store/index.js';
import clientsList from '@src/containers/Commercial/ClientsList/store/index.js';
import allClientsList from '@src/containers/Distributor/AllClientsList/store/index.js';
import history from '@src/containers/Client/HistoryList/store/index.js';

const rootReducer = { navbar, layout, clientsList, commercialsList, allCommercialsList, distributors, history, billingClients, allClientsList };

export default rootReducer;
