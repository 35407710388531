export const options = {
  front: {
    // path: 'consignation.claytonshagal.fr',
    // path: '151.80.130.122',
    path: process.env.NODE_ENV !== 'development'
      ? 'consignation.claytonshagal.fr'
      : 'localhost',
    port: 3000,
  },
  back: {
    // path: 'consignation.claytonshagal.fr/api',
    // path: '151.80.130.122',
    path: process.env.NODE_ENV !== 'development'
      ? 'consignation.claytonshagal.fr/api'
      : 'localhost',
    port: 8000,
  }
};