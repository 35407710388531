import _ from 'lodash';
import { paginateArray } from "../../../../utility/Utils";

export const filterDistributors = ({ allData, params }) => {
  try {
  //  console.log('filter, ', allData);
    // eslint-disable-next-line object-curly-newline
    const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = params;
  //  console.log('sortColumn = ', sortColumn);
    let dataToSort = _.cloneDeep(allData);
    /* eslint-enable */

    const dataAsc = dataToSort.distributors.sort((a, b) => {
      if (sortColumn === 'isUpToDate') {
        b = { isUpToDate: b.lastReport._seconds };
        a = { isUpToDate: a.lastReport._seconds };
      }
    //  console.log('\n\nsort, ', sort, sortColumn);
    //  console.log('sort2, ', a, b);
      if (a[sortColumn]) {
      //  console.log('a', a[sortColumn], b[sortColumn]);
        return a[sortColumn] < b[sortColumn] ? -1 : 1;
      } else {
      //  console.log('ca else');
        const splitColumn = sortColumn.split('.');
        const columnA = a[splitColumn[0]][splitColumn[1]];
        const columnB = b[splitColumn[0]][splitColumn[1]];
        return columnA < columnB ? -1 : 1;
      }
    });
  //  console.log('dataAsc', dataAsc);

    const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse();
  //  console.log('filter3');

    const queryLowered = q.toLowerCase();
  //  console.log('filter4');
    const filteredData = dataToFilter.filter(distributor => {
    //  console.log(distributor);
      if (String('paid').includes(queryLowered) && distributor.balance === 0) { // TODO: remove this
        return distributor.balance === 0;
      } else {
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        return (
          (distributor.company.toLowerCase().includes(queryLowered) ||
            distributor.isUpToDate.toLowerCase().includes(queryLowered) ||
            String(distributor.stockPercentage).toLowerCase().includes(queryLowered) ||
            distributor.city.toLowerCase().includes(queryLowered)) &&
          (!status || String(distributor.active).toLowerCase() === (status.toLowerCase() || String(distributor.active).toLowerCase()))
        );
      }
    });
  //  console.log('filter5');
    /* eslint-enable  */

  //  console.log('filteredData', {
    //   allData: dataToSort.distributors,
    //   total: filteredData.length,
    //   distributors: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    // });
    return {
      allData: dataToSort.distributors,
      total: filteredData.length,
      distributors: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    };
  } catch (error) {
  //  console.log(error);
  }
};