import jwtDefaultConfig from "@src/auth/jwt/jwtConfig";

import {
  USER_ROLE_ADMIN,
  USER_ROLE_CLIENT,
  USER_ROLE_COMMERCIAL,
  USER_ROLE_DISTRIBUTOR,
} from '@src/misc/roles';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  // console.log('user data = ', localStorage.getItem(jwtDefaultConfig.storageUserDataKeyName));
  // console.log('refresh local = ', localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName));
  // console.log('refresh session = ', sessionStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName));
  if (localStorage.getItem(jwtDefaultConfig.storageUserDataKeyName)
    && (localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName) || sessionStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName)))
    return (true);
  //  console.log('NOT LOGGED ID', (localStorage.getItem(jwtDefaultConfig.storageUserDataKeyName)
  // && (localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName) || sessionStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName))));
  return (null);
};
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  // console.log('userRole = ', userRole);
  if (userRole === USER_ROLE_ADMIN) return '/admin';
  else if (userRole === USER_ROLE_CLIENT) return '/stock';
  else if (userRole === USER_ROLE_COMMERCIAL) return '/clients';
  else if (userRole === USER_ROLE_DISTRIBUTOR) return '/commercials';
  else return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
});

// ** Returns paginated array
export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage);

export const toDateTime = (secs) => {
  var t = new Date(1970, 0, 1); // Epoch
  if (secs)
    t.setSeconds(secs);
  return t;
};

export const errorHandlingRedirect = (navigate, res) => {
  if (!res)
    navigate('/error', { replace: true });
  else if (res?.status === 403 || res?.status === 401) {
    //  console.log('errorHandlingRedirect', res?.status, res?.data);
    if (res?.data === 'Forbidden')
      navigate('/forbidden', { replace: true });
    else // (res?.data === 'Error' || 'Unauthorized')
      navigate('/error', { replace: true, state: { logout: true } });
  }
  else if (res.status === 404)
    navigate('/not-found', { replace: true });
};

export const getUpToDateStatus = (clientData) => {
  const deadline = new Date();
  const currentDate = new Date();

  deadline.setMonth(deadline.getMonth() + 1, 1);

  const lastReport = new Date(clientData.lastReport ? clientData.lastReport._seconds * 1000 : new Date(0));
  const daysSinceLastReport = Math.floor((currentDate.getTime() - lastReport.getTime()) / (1000 * 60 * 60 * 24));

  return (lastReport.getMonth() !== currentDate.getMonth() ? 'Pas à jour' : daysSinceLastReport > 15 ? 'Partiellement à jour' : 'À jour');
};

export const getRoleColor = (role) => {
  switch (role) {
    case USER_ROLE_ADMIN:
      return 'admin';
    case USER_ROLE_CLIENT:
      return 'client';
    case USER_ROLE_COMMERCIAL:
      return 'commercial';
    case USER_ROLE_DISTRIBUTOR:
      return 'distributor';
    default:
      return 'secondary';
  }
};

export const formattedPrice = (price) => {
  return ((+Number(price).toFixed(2)).toLocaleString("fr", { minimumFractionDigits: 2 }));
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatElision = (wordBefore, word) => {
  const letter = word[0].toLowerCase();
  if (letter === 'a'
    || letter === 'e'
    || letter === 'i'
    || letter === 'o'
    || letter === 'u'
    || letter === 'y')
    return `${wordBefore[0]}\'${word}`;
  else
    return `${wordBefore} ${word}`;
};

export const getVisitedRoutes = (path) => {
  // const routes = {
  //   '/stock': false,
  //   '/history': false,
  //   '/profile': false,
  // };

  const visitedRoutes = JSON.parse(localStorage.getItem('visitedRoutes'));

  return path !== undefined
    ? (visitedRoutes !== null && visitedRoutes !== undefined && visitedRoutes[path] !== undefined)
    : visitedRoutes;
};

export const getActualWindowSize = () => {
  if (window.innerWidth >= 0 && window.innerWidth < 576)
    return ('xs');
  if (window.innerWidth >= 576 && window.innerWidth < 768)
    return ('sm');
  if (window.innerWidth >= 768 && window.innerWidth < 992)
    return ('md');
  if (window.innerWidth >= 992 && window.innerWidth < 1200)
    return ('lg');
  if (window.innerWidth >= 1200 && window.innerWidth < 1400)
    return ('xl');
  if (window.innerWidth >= 1400)
    return ('xxl');
};

export const isWindowThisSize = (size) => {
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
  const actualWindowSize = getActualWindowSize();
  const actualWindowSizeIndex = sizes.indexOf(actualWindowSize);
  const sizeIndex = sizes.indexOf(size);
  return actualWindowSizeIndex >= sizeIndex;
};

export const getRoleNameByRole = role => {
  switch (role) {
    case USER_ROLE_CLIENT:
      return 'client';
    case USER_ROLE_COMMERCIAL:
      return 'commercial';
    case USER_ROLE_DISTRIBUTOR:
      return 'distributeur';
    default:
      return 'client';
  }
};

export const getSupervisorNameByRole = role => {
  switch (role) {
    case USER_ROLE_CLIENT:
      return 'commercial';
    case USER_ROLE_COMMERCIAL:
      return 'distributeur';
    default:
      return 'commercial';
  }
};