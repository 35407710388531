import _ from 'lodash';
import { paginateArray } from "../../../../utility/Utils";

import { getUpToDateStatus } from '@utils';

export const filterClients = ({ allData, params }) => {
  try {
    // console.log('filter, ', allData);
    // eslint-disable-next-line object-curly-newline
    const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = params;
    // console.log('sortColumn = ', sortColumn);
    let dataToSort = _.cloneDeep(allData);
    /* eslint-enable */

    const dataAsc = dataToSort.clientsList.sort((a, b) => {
      if (sortColumn === 'isUpToDate') {
        b = { isUpToDate: b.lastReport._seconds };
        a = { isUpToDate: a.lastReport._seconds };
      }

      // console.log('\n\nsort, ', sort, sortColumn);
      if (a[sortColumn] !== undefined) {
        return a[sortColumn] < b[sortColumn] ? -1 : 1;
      } else {
        const splitColumn = sortColumn.split('.');
        const columnA = a[splitColumn[0]][splitColumn[1]];
        const columnB = b[splitColumn[0]][splitColumn[1]];
        return columnA < columnB ? -1 : 1;
      }
    });
    // console.log('dataAsc', dataAsc);

    const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse();
    // console.log('filter3');

    const queryLowered = q.toLowerCase();
    // console.log('filter4');
    // console.log('qwefiuqwkjhnfdiqow', dataToFilter);
    const filteredData = dataToFilter.filter(client => {
      // console.log('le client = ', client);
      // console.log('status a jour = ', getUpToDateStatus(client));
      if (String('paid').includes(queryLowered) && client.balance === 0) { // TODO: remove this
        return client.balance === 0;
      } else {
      //  console.log('status = ', status);
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        return (
          (client?.company?.toLowerCase().includes(queryLowered) ||
            client?.isUpToDate?.toLowerCase().includes(queryLowered) ||
            String(client?.stockPercentage)?.toLowerCase().includes(queryLowered) ||
            client?.city?.toLowerCase().includes(queryLowered)) &&
          (!status || client?.isUpToDate?.toLowerCase() === status.toLowerCase())
        );
      }
    });
    // console.log('filter5');
    /* eslint-enable  */

  //  console.log('filteredData', {
    //   allData: dataToSort.clientsList,
    //   total: filteredData.length,
    //   clientsList: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    // });
    return {
      allData: dataToSort.clientsList,
      total: filteredData.length,
      clientsList: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    };
  } catch (error) {
  //  console.log(error);
  }
};