import { options } from '../../options';

import { API } from '../../utility/env';

// ** Auth Endpoints
export default {
  loginEndpoint: `${API}/user/login`,
  registerEndpoint: `${API}/user/register`,
  refreshEndpoint: `${API}/user/refresh-token`,
  logoutEndpoint: `${API}/user/logout`,
  logoutClearEndpoint: `${API}/user/logout/clear`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  // tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  // storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData',
  storageCommercialDataKeyName: 'commercialData',
};
