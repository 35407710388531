const roles_list = {
  admin: 0,
  client: 1,
  commercial: 2,
  distributor: 3,
};

const USER_ROLE_ADMIN = roles_list.admin;
const USER_ROLE_CLIENT = roles_list.client;
const USER_ROLE_COMMERCIAL = roles_list.commercial;
const USER_ROLE_DISTRIBUTOR = roles_list.distributor;

module.exports = {
  USER_ROLE_ADMIN,
  USER_ROLE_CLIENT,
  USER_ROLE_COMMERCIAL,
  USER_ROLE_DISTRIBUTOR,
};